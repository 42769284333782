import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { axios } from 'api'
import { useStripeCheckout } from 'context'
import { gift_card_added_event } from 'gtm/pushEvents'

const DeliveryFormProvider = ({ cards, children, initialValues, plans }) => {
  const getStripe = useStripeCheckout()

  const calculatedInitialValues = initialValues || {
    // To form
    toName: '',
    toEmail: '',
    message: '',
    deliveryMethod: 'email',
    // Recipient form
    fromName: '',
    fromEmail: '',
    // Landing page
    card: 0,
    cards,
    plans,
    selectedPlan: plans.length > 0 ? plans[0].id : ''
  }

  const handleSubmit = async (
    {
      card,
      deliveryMethod,
      fromEmail,
      fromName,
      message,
      selectedPlan,
      toEmail,
      toName
    },
    { setStatus }
  ) => {
    setStatus(null)

    const stripe = await getStripe()
    try {
      const { data } = await axios.post('/gifting/purchase', {
        delivery_method: deliveryMethod,
        gift_offer_id: selectedPlan,
        giftcard_slug: cards[card].slug,
        purchaser_email: fromEmail,
        purchaser_name: fromName,
        message,
        to_name: toName,
        to_email: toEmail
      })
      if (data.sessionId) {
        gift_card_added_event()
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId
        })

        if (error) {
          setStatus(error.message)
        }
      } else {
        setStatus(
          '[No sessionId provided.] Please contact support@alomoves.com for additional assistance.'
        )
      }
    } catch (error) {
      if (error.response.data.error) setStatus(error.response.data.error)
    }
  }

  return (
    <Formik
      initialValues={calculatedInitialValues}
      validationSchema={Yup.object().shape({
        toName: Yup.string().required('Recipient name is required.'),
        toEmail: Yup.string().when('deliveryMethod', {
          is: 'email',
          then: Yup.string()
            .email('Recipient email must be a valid email.')
            .required(
              'Recipient email is required when the delivery method is set to email.'
            )
        }),
        message: Yup.string().max(200, 'too_long'),
        fromName: Yup.string().required('Sender name is required.'),
        fromEmail: Yup.string()
          .email('Sender email must be a valid email.')
          .required('Sender email is required.')
      })}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}

DeliveryFormProvider.propTypes = {
  children: PropTypes.node,
  initialValues: PropTypes.shape({
    card: PropTypes.number,
    selectedPlan: PropTypes.string,
    toName: PropTypes.string,
    message: PropTypes.string,
    deliveryMethod: PropTypes.string,
    toEmail: PropTypes.string,
    fromName: PropTypes.string,
    fromEmail: PropTypes.string
  })
}

export default DeliveryFormProvider
